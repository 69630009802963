@import "../../../mixins.scss";

.cox-review {
  position: relative;
  .inner-hero {
    width: 100%;
    background: url(../../../assets/images/hero-bg.svg) center,
      linear-gradient(
        to right,
        var(--primary-color),
        var(--secondary-color-one)
      );
    background-size: cover;

    .head {
      margin-top: 20px;
      .sect-heading,
      .sect-desc {
        color: #fff;
      }
    }
    .package-table {
      display: none;
    }
  }
  .is-cox-good {
    background: var(--secondary-color-three);
    padding: 80px 5%;
    .bold-blue {
      color: #0070ff;
      @include h6;
    }
    .custom-table {
      box-shadow: var(--secndary-shadow);
      table {
        thead {
          tr {
            th {
              text-align: center;
              .icon {
                font-size: 20px;
              }
            }
          }
        }
        tbody {
          background: var(--secondary-color-three);
          tr {
            td:nth-of-type(1) {
              color: #000;
            }
            td {
              text-align: center;
            }
          }
        }
      }
    }
    ul {
      list-style: none;
      margin: 0px;
      li {
        b {
          @include p2($fw: bold);
        }
      }
    }
  }
  .important-links {
    .all-links {
      @include flex;
      flex-wrap: wrap;
      margin-top: 20px;
      a {
        padding: 12px;
        margin: 12px;
        @include p2($fw: 400);
        text-decoration: underline;
        color: var(--primary-color);
        border: none;
        border-radius: 24px;
        background: #e6f1ff;
        transition: var(--basic);
        text-align: center;
        &:hover {
          color: var(--secondary-color-two);
          background: var(--primary-color);
        }
      }
    }
  }
  .cox-blank {
    background: var(--secondary-color-three);
    .channels {
      background-color: var(--secondary-color-two);
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.14);
      img.mark {
        width: 44px;
        margin: 0px 0px 25px;
      }
      a {
        @include p2($fw: 400);
        color: #0070ff;
        &:hover {
          text-decoration: underline;
          cursor: pointer;
        }
      }
      ul {
        li {
          @include p3();
          font-weight: 400;
          span {
            a {
              @include p2($fw: 300);
              color: #0070ff;
              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
      }
      hr.hr {
        border-color: #0070ff;
        margin: 15px 0px;
      }
      .btn {
        overflow: hidden;
        padding: 22px 0 22px 0;
        @include p2($fw: 400);
        color: var(--secondary-color-one);
        text-transform: capitalize;

        &:nth-of-type(1) {
          color: #a5a5a5;
          text-align: right;
        }
        a {
          background: var(--primary-color);
          padding: 11px 24px;
          border: none;
          border-radius: 12px 0 12px 0;
          color: var(--secondary-color-two);
          @include btActive;
          box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.14);
          transition: var(--basic);
          &:hover {
            border-radius: 0 12px 0 12px;
            text-decoration: underline;
          }
        }
      }
    }
  }
  .cox-internet-plans-and-prices {
    table {
      tbody {
        background-color: var(--secondary-color-three);
      }
    }
    p.below-table {
      text-align: center;
      font-style: italic;
      @include p3();
    }
  }
  .cox-tv-plans-and-prices {
    background-color: var(--secondary-color-three);
    table {
      tbody {
        background-color: var(--secondary-color-two);
      }
    }
    p.below-table {
      text-align: center;
      font-style: italic;
      @include p3();
    }
    .channels {
      background-color: var(--secondary-color-two);
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.14);
      img.mark {
        width: 44px;
        margin: 0px 0px 25px;
      }
      a {
        @include p2($fw: 400);
        color: #0070ff;
        &:hover {
          text-decoration: underline;
          cursor: pointer;
        }
      }
      ul {
        li {
          @include p3();
          font-weight: 400;
          span {
            a {
              @include p2($fw: 300);
              color: #0070ff;
              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
      }
      hr.hr {
        border-color: #0070ff;
        margin: 15px 0px;
      }
      .btn {
        overflow: hidden;
        padding: 22px 0 22px 0;
        @include p2($fw: 400);
        color: var(--secondary-color-one);
        text-transform: capitalize;

        &:nth-of-type(1) {
          color: #a5a5a5;
          text-align: right;
        }
        a {
          background: var(--primary-color);
          padding: 11px 24px;
          border: none;
          border-radius: 12px 0 12px 0;
          color: var(--secondary-color-two);
          @include btActive;
          box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.14);
          transition: var(--basic);
          &:hover {
            border-radius: 0 12px 0 12px;
            text-decoration: underline;
          }
        }
      }
    }
  }
  .cox-internet-and-tv-bundles {
    table {
      tbody {
        background-color: var(--secondary-color-three);
      }
    }
    p.below-table {
      text-align: center;
      font-style: italic;
      @include p3();
    }
  }
  .cox-competition {
    background: var(--secondary-color-three);
    .two-column {
      display: flex;
      align-items: center;
      div {
        flex: 1;
        background: var(--secondary-color-two);
        box-sizing: border-box;
        margin: 10px;
        padding: 40px;
        border-radius: 24px;
        box-shadow: var(--primary-shadow);
        height: 170px;
        h4 {
          @include h5();
        }
        ul {
          li {
            span {
              a {
                color: var(--primary-color);
                @include p2($fw: 400);
                &:hover {
                  text-decoration: underline;
                }
              }
            }
          }
        }
      }
    }
  }
  .cox-internet-equipment {
    table {
      tbody {
        background-color: var(--secondary-color-three);
      }
    }
    p.below-table {
      text-align: center;
      font-style: italic;
      @include p3();
    }
    .channels {
      background-color: var(--secondary-color-three);
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.14);
      img.mark {
        width: 44px;
        margin: 0px 0px 25px;
      }
      a {
        @include p2($fw: 400);
        color: #0070ff;
        &:hover {
          text-decoration: underline;
          cursor: pointer;
        }
      }
      ul {
        li {
          @include p2();
          font-weight: 400;
          span {
            a {
              @include p2($fw: 300);
              color: #0070ff;
              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
      }
      hr.hr {
        border-color: #0070ff;
        margin: 15px 0px;
      }
      .btn {
        overflow: hidden;
        padding: 22px 0 22px 0;
        @include p2($fw: 400);
        color: var(--secondary-color-one);
        text-transform: capitalize;

        &:nth-of-type(1) {
          color: #a5a5a5;
          text-align: right;
        }
        a {
          background: var(--primary-color);
          padding: 11px 24px;
          border: none;
          border-radius: 12px 0 12px 0;
          color: var(--secondary-color-two);
          @include btActive;
          box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.14);
          transition: var(--basic);
          &:hover {
            border-radius: 0 12px 0 12px;
            text-decoration: underline;
          }
        }
      }
    }
  }
  .final-take {
    background: var(--secondary-color-three);
    .all-links {
      @include flex;
      flex-wrap: wrap;
      margin-top: 20px;
      a {
        padding: 12px;
        margin: 12px;
        @include p2($fw: 400);
        text-decoration: underline;
        color: var(--primary-color);
        border: none;
        border-radius: 24px;
        background: #e6f1ff;
        transition: var(--basic);
        text-align: center;
        &:hover {
          color: var(--secondary-color-two);
          background: var(--primary-color);
        }
      }
    }
    .searchbar {
      max-width: 600px;
      margin: auto;
    }
  }
  .cox-faq {
    .accordion {
      .item {
        background: var(--secondary-color-three);
      }
    }
  }
  .cox-endnote {
    padding: 80px 5%;
    background: var(--secondary-color-three);
    .endnote-container {
      background: var(--secondary-color-two);
      ul {
        list-style: none;
        margin-top: 15px;
        margin-right: 0;
        margin-left: 0;
        margin-bottom: 15px;
        li {
          span {
            a {
              @include p2($fw: 400);
              color: #0070ff;
              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
      }
      .endnote-review {
        @include p1($fw: 300);
      }
      b{
        @include p2($fw: 600);       
      }
    }
  }
  .divider {
    @include flex($jc: space-between);
    background: var(--primary-color);
    padding: 30px 5%;
    h4 {
      flex: 1;
      @include h4;
      color: var(--secondary-color-two);
    }
    p {
      flex: 1;
      text-align: center;
      @include p2;
      color: var(--secondary-color-two);
      span {
        a {
          @include p2($fw: 500);
          text-decoration: underline;
          &:hover {
            color: var(--secondary-color-one);
          }
        }
      }
    }
  }
}
 