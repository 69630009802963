@import "../../../mixins.scss";

.spectrum-mobile {
  position: relative;
  .inner-hero {
    width: 100%;
    background: url(../../../assets/images/hero-bg.svg) center,
      linear-gradient(
        to right,
        var(--primary-color),
        var(--secondary-color-one)
      );
    background-size: cover;

    .head {
      margin-top: 20px;
      .sect-heading,
      .sect-desc {
        color: #fff;
      }
    }
  }
  .best-mobile-bundles-offers {
    background-color: var(--secondary-color-three);
    .below-table {
      @include p2($fw: 300);
      text-align: center;
    }
  }
  .spectrum-mobile {
    background-color: var(--secondary-color-three);
    .below-table {
      @include p2($fw: 300);
      text-align: center;
      margin-top: 15px;
    }
  }
  .spectrum-internet-mobile {
    .below-table {
      @include p2($fw: 300);
      text-align: center;
      margin-top: 15px;
    }
    .channels {
      background-color: var(--secondary-color-three);
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.14);
      .after-heading {
        margin-top: 10px;
        a {
          @include p2($fw: 300);
          color: #0070ff;
          &:hover {
            text-decoration: underline;
            cursor: pointer;
          }
        }
      }
      p {
        span {
          a {
            @include p2($fw: 300);
            color: var(--primary-color);
            &:hover {
              border-radius: 0 12px 0 12px;
              text-decoration: underline;
            }
          }
        }
      }
      ul {
        li {
          @include p2($fw: 300);
          span {
            @include p2($fw: 300);
            color: #0070ff;
          }
        }
      }
      .below-table {
        text-align: center;
      }
      table {
        tbody {
          background-color: var(--secondary-color-three);
        }
      }
      .btn {
        overflow: hidden;
        padding: 22px 0 22px 0;
        @include p2($fw: 400);
        color: var(--secondary-color-one);
        text-transform: capitalize;

        &:nth-of-type(1) {
          color: #a5a5a5;
          text-align: right;
        }
        a {
          background: var(--primary-color);
          padding: 11px 24px;
          border: none;
          border-radius: 12px 0 12px 0;
          color: var(--secondary-color-two);
          @include btActive;
          box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.14);
          transition: var(--basic);
          &:hover {
            border-radius: 0 12px 0 12px;
            text-decoration: underline;
          }
        }
      }
      .channel-list {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        list-style: disc;
        padding: 20px;
        li {
          @include p2($fw: 300);
        }
      }
      .left {
        text-align: left;
      }
    }
    .review-endnote {
      .endnote-container {
        background-color: var(--secondary-color-three);
      }
    }
  }
  .spectrum-phones {
    background-color: var(--secondary-color-three);
    .container {
      display: flex;
      flex-wrap: wrap;
    }
    .column {
      flex: 1;
      margin: 10px;
    }
    .channels {
        background-color: var(--secondary-color-two);
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.14);
        .after-heading {
          margin-top: 10px;
          a {
            @include p2($fw: 300);
            color: #0070ff;
            &:hover {
              text-decoration: underline;
              cursor: pointer;
            }
          }
        }
        p {
          span {
            a {
              @include p2($fw: 300);
              color: var(--primary-color);
              &:hover {
                border-radius: 0 12px 0 12px;
                text-decoration: underline;
              }
            }
          }
        }
        ul {
          li {
            @include p2($fw: 300);
            span {
              @include p2($fw: 300);
              color: #0070ff;
            }
          }
        }
        .below-table {
          text-align: center;
        }
        table {
          tbody {
            background-color: var(--secondary-color-three);
          }
        }
        .btn {
          overflow: hidden;
          padding: 22px 0 22px 0;
          @include p2($fw: 400);
          color: var(--secondary-color-one);
          text-transform: capitalize;
  
          &:nth-of-type(1) {
            color: #a5a5a5;
            text-align: right;
          }
          a {
            background: var(--primary-color);
            padding: 11px 24px;
            border: none;
            border-radius: 12px 0 12px 0;
            color: var(--secondary-color-two);
            @include btActive;
            box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.14);
            transition: var(--basic);
            &:hover {
              border-radius: 0 12px 0 12px;
              text-decoration: underline;
            }
          }
        }
        .channel-list {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          list-style: disc;
          padding: 20px;
          li {
            @include p2($fw: 300);
          }
        }
        .left {
          text-align: left;
        }
      }
  }
  .spectrum-mobile-faq {
    background: var(--secondary-color-three);
    .accordion {
      .item {
        background: var(--secondary-color-two);
      }
    }
  }
}
