@import "../../../mixins.scss";
.xfinity-hidden-costs {
  position: relative;
  .xfinity-hidden-costs-hero {
    position: relative;
    width: 100%;
    height: 329px;
    background: url(../../../assets/images/hero-bg.svg) center,
      linear-gradient(
        to right,
        var(--primary-color),
        var(--secondary-color-one)
      );
    padding: 80px 5% 0 5%;
    text-align: center;
    h1 {
      @include h2;
      color: var(--secondary-color-two);
      text-transform: capitalize;
      margin-top: 20px;
    }
    & > p {
      @include p1;
      color: var(--secondary-color-two);
      text-transform: capitalize;
      line-height: 26px;
      margin: 15px auto;
    }
  }
  .best-internet-tv-offer {
    background-color: var(--secondary-color-three);
    padding: 80px 5% 20px 5%;
    .cards {
      margin-top: -200px;
    }
  }
  .endnote-start {
    background-color: var(--secondary-color-three);
  }
  .important-links {
    .all-links {
      @include flex;
      flex-wrap: wrap;
      margin-top: 20px;
      a {
        padding: 12px;
        margin: 12px;
        @include p2($fw: 400);
        text-decoration: underline;
        color: var(--primary-color);
        border: none;
        border-radius: 24px;
        background: #e6f1ff;
        transition: var(--basic);
        text-align: center;
        &:hover {
          color: var(--secondary-color-two);
          background: var(--primary-color);
        }
      }
    }
  }
  .xfinity-hidden-costs-specs-feature {
    background: var(--secondary-color-three);
    .channels {
      background: var(--secondary-color-two);
      box-shadow: 0px 4px 4px 0px #00000024;
      .after-heading {
        margin-top: 10px;
        a {
          @include p2($fw: 300);
          color: #0070ff;
          &:hover {
            text-decoration: underline;
            cursor: pointer;
          }
        }
      }
      .below-table {
        text-align: left;
      }
      ul {
        li {
          @include p2($fw: 400);
          margin-top: 5px;
        }
      }
      .custom-table {
        box-shadow: var(--secndary-shadow);
        table {
          thead {
            tr {
              th {
                text-align: center;
                .icon {
                  font-size: 20px;
                }
              }
            }
          }
          tbody {
            background: var(--secondary-color-three);
            tr {
              td:nth-of-type(1) {
                color: #000;
              }
              td {
                text-align: center;
              }
            }
          }
        }
      }
    }
    .btn {
      overflow: hidden;
      padding: 22px 0 22px 0;
      @include p2($fw: 400);
      color: var(--secondary-color-one);
      text-transform: capitalize;

      &:nth-of-type(1) {
        color: #a5a5a5;
        text-align: right;
      }
      a {
        background: var(--primary-color);
        padding: 11px 24px;
        border: none;
        border-radius: 12px 0 12px 0;
        color: var(--secondary-color-two);
        @include btActive;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.14);
        transition: var(--basic);
        &:hover {
          border-radius: 0 12px 0 12px;
          text-decoration: underline;
        }
      }
    }
  }
  .xfinity-hidden-costs-internet-plans {
    .channels {
      background: var(--secondary-color-three);
      box-shadow: 0px 4px 4px 0px #00000024;
      .after-heading {
        margin-top: 10px;
        a {
          @include p2($fw: 300);
          color: #0070ff;
          &:hover {
            text-decoration: underline;
            cursor: pointer;
          }
        }
      }
      .below-table {
        text-align: left;
      }
      ul {
        li {
          @include p2($fw: 400);
          margin-top: 5px;
        }
      }
      .custom-table {
        box-shadow: var(--secndary-shadow);
        table {
          thead {
            tr {
              th {
                text-align: left;
                .icon {
                  font-size: 20px;
                }
              }
            }
          }
          tbody {
            background: var(--secondary-color-two);
            tr {
              td:nth-of-type(1) {
                color: #000;
              }
              td {
                text-align: left;
              }
            }
          }
        }
      }
    }
    .btn {
      overflow: hidden;
      padding: 22px 0 22px 0;
      @include p2($fw: 400);
      color: var(--secondary-color-one);
      text-transform: capitalize;

      &:nth-of-type(1) {
        color: #a5a5a5;
        text-align: right;
      }
      a {
        background: var(--primary-color);
        padding: 11px 24px;
        border: none;
        border-radius: 12px 0 12px 0;
        color: var(--secondary-color-two);
        @include btActive;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.14);
        transition: var(--basic);
        &:hover {
          border-radius: 0 12px 0 12px;
          text-decoration: underline;
        }
      }
    }
  }
  .xfinity-hidden-cost-review-faq {
    background: var(--secondary-color-three);
    .accordion {
      .item {
        background: var(--secondary-color-two);
      }
    }
  }
  .intertnet-review-endnote {
    padding: 80px 5%;
    background: var(--secondary-color-two);
    .endnote-container {
      background: var(--secondary-color-three);
      ul {
        margin-top: 15px;
        margin-bottom: 15px;
        list-style: none;
        margin-left: 0;
        margin-right: 0;
        li {
          span {
            a {
              @include p2($fw: 400);
              color: #0070ff;
              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
      }
      .endnote-review {
        @include p1($fw: 300);
      }
    }
  }
}
