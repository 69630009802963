@import "../../../mixins.scss";

.internet {
  .internet-hero {
    width: 100%;
    height: 518px;
    background: url(../../../assets/images/hero-bg.svg) center,
      linear-gradient(
        to right,
        var(--primary-color),
        var(--secondary-color-one)
      );
    padding: 80px 5% 0 5%;
    text-align: center;
    h1 {
      @include h2;
      color: var(--secondary-color-two);
      text-transform: capitalize;
    }
    & > p {
      @include p1;
      color: var(--secondary-color-two);
      text-transform: capitalize;
      line-height: 26px;
      margin: 15px auto;
    }

    .form {
      width: 100%;
      max-width: 608px;
      margin: 40px auto 0 auto;
      & > p {
        @include p1;
        color: var(--secondary-color-two);
        text-transform: capitalize;
        margin-bottom: 12px;
      }
    }
  }

  .best-internet-tv-offer {
    background-color: var(--secondary-color-three);
    padding: 80px 5% 20px 5%;
    .cards {
      margin-top: -200px;
    }
  }

  .internet-providers {
    background-color: var(--secondary-color-three);
    .accordion {
      margin-top: 30px;

      .item {
        background: var(--secondary-color-two);
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.14);
        border: none;
        border-radius: 16px;
        padding: 26px 15px;
        overflow: hidden;
        .title {
          cursor: pointer;
          @include flex($jc: flex-start);
          @include h5;
          text-transform: capitalize;
          span {
            @include flex;
            font-size: 18px;
            background: var(--primary-color);
            color: var(--secondary-color-two);
            height: 20px;
            width: 20px;
            border-radius: 100%;
            margin-right: 16px;
            // padding-right: 1px;
            transition: var(--advance);
          }

          &:hover {
            span {
              transform: rotate(180deg);
            }
          }
        }
        .accordion-ol {
          height: auto;
          max-height: 0px;
          overflow: hidden;
          transition: all 0.5s ease;
          // margin-left: 36px;
          @include flex($jc: flex-start);
          flex-wrap: wrap;
          border: none;
          border-radius: 24px;
          background: var(--secondary-color-two);
          padding: 0px 50px;

          li {
            margin: 25px 0;
            transition: var(--basic);
            min-width: 33%;
            flex: 1;
            a {
              @include p2($fw: 400);
              text-decoration: underline;
              color: var(--secondary-color-one);
            }
            &:hover {
              a {
                color: var(--primary-color);
              }
            }

            &::marker {
              @include p2;
              color: #a5a5a5;
            }
          }
        }
        .show {
          max-height: 999px;
          transition: all 0.5s ease;
          margin-top: 12px;
        }
      }
    }
  }

  .best-isp-deals {
  }

  .head-to-head-comparisons {
    background: var(--secondary-color-three);
  }

  .cheap-internet-options {
  }

  .need-for-speed {
    background: var(--secondary-color-three);
  }

  .fast-and-reliable {
  }

  .modems-and-routers {
    background: var(--secondary-color-three);
  }
}
