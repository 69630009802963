@import "../../../mixins.scss";

.spectrum-internet-cheap-plan {
  position: relative;
  .inner-hero {
    width: 100%;
    background: url(../../../assets/images/hero-bg.svg) center,
      linear-gradient(
        to right,
        var(--primary-color),
        var(--secondary-color-one)
      );
    background-size: cover;

    .head {
      margin-top: 20px;
      .sect-heading,
      .sect-desc {
        color: #fff;
      }
    }
  }
  .is-spectrum-good {
    background: var(--secondary-color-three);
    padding: 80px 5%;

    .custom-table {
      box-shadow: var(--secndary-shadow);
      table {
        thead {
          tr {
            th {
              text-align: center;
              .icon {
                font-size: 20px;
              }
            }
          }
        }
        tbody {
          background: var(--secondary-color-three);
          tr {
            td:nth-of-type(1) {
              color: #000;
            }
            td {
              text-align: center;
            }
          }
        }
      }
    }
  }
  .important-links {
    .all-links {
      @include flex;
      flex-wrap: wrap;
      margin-top: 20px;
      a {
        padding: 12px;
        margin: 12px;
        @include p2($fw: 400);
        text-decoration: underline;
        color: var(--primary-color);
        border: none;
        border-radius: 24px;
        background: #e6f1ff;
        transition: var(--basic);
        text-align: center;
        &:hover {
          color: var(--secondary-color-two);
          background: var(--primary-color);
        }
      }
    }
  }
  .cheap-spectrum-internet-plan {
    background-color: var(--secondary-color-three);
    .below-table {
      text-align: center;
      @include p2($fw: 300);
      margin-top: 15px;
    }
    .channels {
      background-color: var(--secondary-color-two);
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.14);
      .after-heading {
        margin-top: 10px;
        a {
          @include p2($fw: 300);
          color: #0070ff;
          &:hover {
            text-decoration: underline;
            cursor: pointer;
          }
        }
      }
      p {
        span {
          a {
            @include p2($fw: 300);
            color: var(--primary-color);
            &:hover {
              border-radius: 0 12px 0 12px;
              text-decoration: underline;
            }
          }
        }
      }
      img.wireless {
        width: 50px;
        margin: 15px 0px;
      }
    }
  }
  .internet-bundles {
    background: var(--secondary-color-two);
    table {
      tbody {
        background-color: var(--secondary-color-three);
      }
    }
    .below-table {
      text-align: center;
      @include p2($fw: 300);
      margin-top: 15px;
    }
  }
  .final-take {
    background-color: var(--secondary-color-three);
    .head {
      p {
        span {
          @include p2($fw: 300);
          color: #0070ff;
          &:hover {
            text-decoration: underline;
            cursor: pointer;
          }
        }
      }
    }
    .all-links {
      @include flex;
      flex-wrap: wrap;
      margin-top: 20px;
      a {
        padding: 12px;
        margin: 12px;
        @include p2($fw: 400);
        text-decoration: underline;
        color: var(--primary-color);
        border: none;
        border-radius: 24px;
        background: #e6f1ff;
        transition: var(--basic);
        text-align: center;
        &:hover {
          color: var(--secondary-color-two);
          background: var(--primary-color);
        }
      }
    }
  }
  .spectrum-faq {
    background: var(--secondary-color-two);
    .accordion {
      .item {
        background: var(--secondary-color-three);
      }
    }
  }
  .spectrum-review-endnote {
    padding: 80px 5%;
    background: var(--secondary-color-three);
    .endnote-container {
      background: var(--secondary-color-two);
      .bold {
        @include p2($fw: 600);
      }
      .below-table {
        @include p2($fw: 300);
        margin-top: 10px;
        font-style: italic;
      }
      ul {
        margin-top: 15px;
        margin-bottom: 15px;
        list-style: none;
        margin-left: 0px;
        margin-right: 0px;
        li {
          span {
            a {
              @include p2($fw: 400);
              color: #0070ff;
              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
      }
      .endnote-review {
        @include p1($fw: 300);
      }
    }
  }
}
