@import "../../mixins.scss";

.package-table {
  table {
    width: 100%;
    border: none;
    overflow: hidden;
    border-radius: 24px;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.14);
    thead {
      background: #161616;
      tr {
        th {
          stroke: none;
          @include h6;
          color: var(--secondary-color-two);
          padding: 16px 0px;
          text-align: left;
        //   padding-left: 3px;
        }
      }
    }
    tbody {
      background: #fff;
      tr {
        td {
          max-width: 80px;
          overflow: hidden;
          padding: 22px 0 22px 0;
          border-bottom: 1px solid #eeeeee;
          @include p2($fw: 400);
          color: var(--secondary-color-one);

          &:nth-of-type(1) {
            color: #a5a5a5;
            text-align: center;
          }
          a {
            background: var(--primary-color);
            padding: 11px 24px;
            border: none;
            border-radius: 12px 0 12px 0;
            color: var(--secondary-color-two);
            @include btActive;
            box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.14);
            transition: var(--basic);
            &:hover {
              border-radius: 0 12px 0 12px;
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
}
