@import "../../mixins.scss";

.sub-nav {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  z-index: 99;
  overflow-x: scroll;
  width: 90%;
  margin: auto;
  a {
    flex: 1;
    background: #7d7d7d;
    padding: 0px 10px;
    height: 36px;
    border: none;
    border-radius: 24px;
    margin: 20px 6px 12px 6px;
    @include flex;
    transition: var(--basic);

    p {
      @include btUnctive;
      color: var(--secondary-color-one);
      white-space: nowrap;
      margin-left: 6px;
    }

    .icon-box {
      width: 22px;
      height: 22px;
      max-width: 0px;
      max-height: 0px;
      visibility: hidden;
      opacity: 0;
      border: none;
      @include flex;
      border-radius: 100%;
      background: var(--primary-color);
      padding: 2px;
      transition: var(--basic);
      .check-icon {
        color: var(--secondary-color-two);
        font-size: 17px;
      }
    }

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      //   background: var(--secondary-color-two);

      p {
        color: var(--secondary-color-two);
      }
    }
  }

  .active {
    background-color: var(--secondary-color-two);
    p {
      color: var(--primary-color);
      @include btActive;
    }
    .icon-box {
      opacity: 1;
      @include flex;
      max-width: 24px;
      max-height: 24px;
      visibility: visible;
    }

    &:hover {
      background-color: var(--secondary-color-two);
      p {
        color: var(--primary-color);
      }
    }
  }

  //   Scrollbar

  /* width */
  &::-webkit-scrollbar {
    height: 4px;
    border-radius: 24px;
    overflow: hidden;
    border: none;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f188;
    border-radius: 24px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    // background: #000;
    background: var(--primary-color);
    border-radius: 24px;

  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: var(--hover-color);
  }
}
