@import "../../../mixins.scss";

.spectrum-availability {
  position: relative;
  .spectrum-costs-hero {
    position: relative;
    width: 100%;
    height: 329px;
    background: url(../../../assets/images/hero-bg.svg) center,
      linear-gradient(
        to right,
        var(--primary-color),
        var(--secondary-color-one)
      );
    padding: 80px 5% 0 5%;
    text-align: center;
    h1 {
      @include h2;
      color: var(--secondary-color-two);
      text-transform: capitalize;
      margin-top: 20px;
    }
    & > p {
      @include p1;
      color: var(--secondary-color-two);
      text-transform: capitalize;
      line-height: 26px;
      margin: 15px auto;
    }
  }
  .best-internet-tv-offer {
    background-color: var(--secondary-color-three);
    padding: 80px 5% 20px 5%;
    .cards {
      margin-top: -200px;
    }
  }
  .endnote-start {
    background-color: var(--secondary-color-three);
    .endnote-container {
      img.coveragemap {
        width: 100%;
        margin-top: 15px;
      }
    }
  }
  .tv-availability {
    background: var(--secondary-color-two);
    .searchbar {
      background: var(--secondary-color-three);
      max-width: 600px;
      margin: auto;
    }
  }
  .internet-availability {
    background: var(--secondary-color-three);
    .searchbar {
      background: var(--secondary-color-two);
      max-width: 600px;
      margin: auto;
    }
  }
  .spectrum-availability {
    background: var(--secondary-color-two);
    .channels {
      background: var(--secondary-color-three);
      box-shadow: 0px 4px 4px 0px #00000024;
      .after-heading {
        margin-top: 10px;
        a {
          @include p2($fw: 300);
          color: #0070ff;
          &:hover {
            text-decoration: underline;
            cursor: pointer;
          }
        }
      }
      .below-table {
        text-align: left;
      }
      ul {
        li {
          @include p2($fw: 400);
          margin-top: 5px;
          a {
            @include p2($fw: 400);
            color: #0070ff;
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
      .grid-ul {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
      }
      .endnote-start {
        margin-top: 15px;
        .endnote-container {
          background-color: var(--secondary-color-three);
        }
      }
      .custom-table {
        box-shadow: var(--secndary-shadow);
        table {
          thead {
            tr {
              th:nth-of-type(1){
                padding: 16px ;
              }
              th {
                .icon {
                  font-size: 20px;
                }
              }
            }
          }
          tbody {
            background: var(--secondary-color-two);
            tr {
              td:nth-of-type(1) {
                color: #000;
                text-align: left;
                padding-left: 16px ;
              }
            }
          }
        }
      }
    }
    .searchbar {
      background: var(--secondary-color-two);
      max-width: 600px;
      margin-top: 15px;
    }
  }
  .spectrum-faq {
    background: var(--secondary-color-three);
    .accordion {
      .item {
        background: var(--secondary-color-two);
      }
    } 
  }
  .spectrum-bundles-endnote {
    padding: 80px 5%;
    background: var(--secondary-color-two);
    .endnote-container {
      background: var(--secondary-color-three);
      .bold {
        @include p2($fw: 600);
      }
      .below-table {
        @include p2($fw: 300);
        margin-top: 10px;
        font-style: italic;
      }
      ul {
        margin-top: 15px;
        margin-bottom: 15px;
        list-style: none;
        margin-left: 0px;
        margin-right: 0px;
        li {
          span {
            a {
              @include p2($fw: 400);
              color: #0070ff;
              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
      }
      .endnote-review {
        @include p1($fw: 300);
      }
    }
  }
}
