@import "../../../mixins.scss";

.tv {
  .tv-hero {
    width: 100%;
    height: 518px;
    background: url(../../../assets/images/hero-bg.svg) center,
      linear-gradient(
        to right,
        var(--primary-color),
        var(--secondary-color-one)
      );
    padding: 80px 5% 0 5%;
    text-align: center;
    h1 {
      @include h2;
      color: var(--secondary-color-two);
      text-transform: capitalize;
    }
    & > p {
      @include p1;
      color: var(--secondary-color-two);
      text-transform: capitalize;
      line-height: 26px;
      margin: 15px auto;
    }

    .form {
      width: 100%;
      max-width: 608px;
      margin: 30px auto 0 auto;
      & > p {
        @include p1;
        color: var(--secondary-color-two);
        text-transform: capitalize;
        margin-bottom: 12px;
      }
    }
  }

  .best-internet-tv-offer {
    background-color: var(--secondary-color-three);
    padding: 80px 5% 20px 5%;
    .cards {
      margin-top: -200px;
      @include flex();
      flex-wrap: wrap;
    }
  }

  .tv-providers {
    background-color: var(--secondary-color-three);
    .cards {
      margin-top: 20px;
      @include flex();
      flex-wrap: wrap;
    }
  }

  .the-internet-for-deals {
    .cards {
      margin-top: 20px;
      @include flex();
      flex-wrap: wrap;
    }
  }

  .comparing-tv-providers {
    background: var(--secondary-color-three);
    width: 100%;
    .comparison-table {
      margin-top: 20px;
      width: 100%;
      table {
        width: 100%;
        border: none;
        overflow: hidden;
        border-radius: 24px 24px 0 0;
        box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.14);
        thead {
          background: #161616;
          tr {
            th {
              stroke: none;
              @include h6;
              color: var(--secondary-color-two);
              padding: 16px 0px;
              text-align: left;
            }
          }
        }
        tbody {
          background: #fff;
          tr {
            td {
              padding: 22px 0px;
              border-bottom: 1px solid #eeeeee;
              @include p2($fw: 400);
              color: var(--secondary-color-one);

              &:nth-of-type(1) {
                color: #a5a5a5;
                text-align: center;
              }
              &:nth-of-type(2) {
                text-decoration: underline;
              }
              a {
                background: var(--primary-color);
                padding: 11px 24px;
                border: none;
                border-radius: 12px 0 12px 0;
                color: var(--secondary-color-two);
                @include btActive;
                box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.14);
                transition: var(--basic);
                &:hover {
                  border-radius: 0 12px 0 12px;
                  text-decoration: underline;
                }
              }
            }
          }
        }
      }
    }
  }

  .channel-lineup {
    .all-links {
      @include flex;
      flex-wrap: wrap;
      margin-top: 20px;
      a {
        padding: 12px;
        margin: 12px;
        @include p2($fw: 400);
        text-decoration: underline;
        color: var(--primary-color);
        border: none;
        border-radius: 24px;
        background: #e6f1ff;
        transition: var(--basic);
        text-align: center;
        &:hover {
          color: var(--secondary-color-two);
          background: var(--primary-color);
          text-decoration: none;
        }
      }
    }
  }

  .streaming-services {
    background: url(../../../assets/images/hero-bg.svg) center,
      var(--secondary-color-one);
    .head {
      .sect-heading {
        color: var(--secondary-color-two);
      }
      .sect-desc {
        color: var(--secondary-color-two);
      }
    }

    .cards {
      margin-top: 20px;
      @include flex();
      flex-wrap: wrap;
    }

    & > a {
      @include btActive;
      color: var(--secondary-color-two);
      transition: var(--basic);
      @include flex($jc: flex-end);
      text-transform: capitalize;
      width: 100%;
      margin-top: 10px;
      .right-arrow-icon {
        font-size: 14px;
        margin-left: 6px;
        margin-top: 4px;
      }
      &:hover {
        text-decoration: underline;
      }
    }
  }

  .latest-tv-gadgets {
    .cards {
      margin-top: 20px;
      @include flex();
      flex-wrap: wrap;
    }
  }

  .head-to-head-comparisons{
    background: var(--secondary-color-three);
  }
}
