@import "../../../mixins.scss";

.xfinity-deals {
  position: relative;

  .top-xfinity-deals {
    .cards {
      .package-card {
        background: var(--secondary-color-three);
      }
    }
  }

  .limited-time-offer{
    padding: 80px 5%;
    background: var(--secondary-color-three);
  }
  .btn {
    overflow: hidden;
    padding: 22px 0 22px 0;
    @include p2($fw: 400);
    color: var(--secondary-color-one);
    text-transform: capitalize;
    text-align: right;

    &:nth-of-type(1) {
      color: #a5a5a5;
      text-align: right;
    }
    a {
      background: var(--primary-color);
      padding: 11px 24px;
      border: none;
      border-radius: 12px 0 12px 0;
      color: var(--secondary-color-two);
      @include btActive;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.14);
      transition: var(--basic);
      &:hover {
        border-radius: 0 12px 0 12px;
        text-decoration: underline;
      }
    }
  }
  .important-links {
    background: var(--secondary-color-three);
    .all-links {
      @include flex;
      flex-wrap: wrap;
      margin-top: 20px;
      a {
        padding: 12px;
        margin: 12px;
        @include p2($fw: 400);
        text-decoration: underline;
        color: var(--primary-color);
        border: none;
        border-radius: 24px;
        background: var(--secondary-color-two);
        transition: var(--basic);
        text-align: center;
        box-shadow: var(--primary-shadow);
        &:hover {
          color: var(--secondary-color-two);
          background: var(--primary-color);
          text-decoration: none;
        }
      }
    }
  }

  .xfinity-deals{
    background: var(--secondary-color-three);

    .endnote{
      margin-top: 30px;
    }
  }
  .xfinity-faq {
    background: var(--secondary-color-two);
    .accordion {
      .item {
        background: var(--secondary-color-three);
      }
    }
  }
  .intertnet-review-endnote {
    padding: 80px 5%;
    background: var(--secondary-color-three);
    .endnote-container {
      background: var(--secondary-color-two);
      ul {
        margin-top: 15px;
        margin-bottom: 15px;
        list-style: none;
        margin-left: 0;
        margin-right: 0;
        li {
          span {
            a {
              @include p2($fw: 400);
              color: #0070ff;
              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
      }
      .endnote-review {
        @include p1($fw: 300);
      }
    }
  }
}
