@import "../../../mixins.scss";

.cox-channel-line-up {
  position: relative;
  .inner-hero {
    width: 100%;
    background: url(../../../assets/images/hero-bg.svg) center,
      linear-gradient(
        to right,
        var(--primary-color),
        var(--secondary-color-one)
      );
    background-size: cover;

    .head {
      margin-top: 20px;
      .sect-heading,
      .sect-desc {
        color: #fff;
      }
    }
  }
  .is-cox-good {
    background: var(--secondary-color-three);
    padding: 80px 5%;
    .bold-blue {
      color: #0070ff;
      @include h6;
    }
    ul {
      list-style: none;
      margin: 0px;
      li {
        b {
          @include p2($fw: bold);
        }
        a {
          @include p2($fw: 400);
          color: #0070ff;
        }
      }
    }
  }
  .cox-tv-channels-lineup {
    background: var(--secondary-color-two);
    .custom-table {
      box-shadow: var(--secndary-shadow);
      table {
        thead {
          tr {
            th {
              text-align: left;
            }
            th:nth-of-type(4),
            th:nth-of-type(5),
            th:nth-of-type(6),
            th:nth-of-type(7),
            th:nth-of-type(8) {
              text-align: center;
            }
          }
        }
        tbody {
          background: var(--secondary-color-three);
          tr {
            td {
              text-align: left;
            }
            th {
              @include p2($fw: 300);
              text-align: left;
              padding: 16px 0px;
              svg.cross-icon {
                background: #a5a5a5;
                color: #fff;
                padding: 6px;
                border-radius: 50px;
                width: 24px;
                height: 24px;
              }
              svg.check-icon {
                background: #0070ff;
                color: #fff;
                padding: 6px;
                border-radius: 50px;
                width: 24px;
                height: 24px;
              }
            }
            th:nth-of-type(1) {
              text-align: center;
              padding: 0px 5px;
            }
            th:nth-of-type(4),
            th:nth-of-type(5),
            th:nth-of-type(6),
            th:nth-of-type(7),
            th:nth-of-type(8) {
              text-align: center;
            }
          }
        }
      }
    }
    .hash-linking {
      display: grid;
      grid-template-columns: repeat(26, 1fr);
      text-transform: uppercase;
      @include p2($fw: 400);
      color: var(--primary-color);
      text-align: center;
      .alphabets {
        background: var(--secondary-color-three);
        border-radius: 50px;
        margin: 0px 5px;
        padding: 11px;
        border-color: var(--primary-color);
        border: 1px solid;
      }
    }
  }
  .cox-tv-channels-packs {
    background: var(--secondary-color-three);
    .row {
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      .columns {
        flex: 1;
        box-sizing: border-box;
        background: var(--secondary-color-two);
        box-shadow: var(--primary-shadow);
        border-radius: 24px;
        padding: 40px;
        margin: 10px;
        height: 400px;
        h3 {
          @include h5();
        }
        p {
          @include p2($fw: 400);
          b {
            @include p2($fw: bold);
          }
          span {
            @include p3($fw: 300);
          }
        }
        h6 {
          @include p2($fw: medium);
        }
        ul {
          li {
            @include p2($fw: 400);
          }
        }
      }
    }
  }
  .cox-add-on-packages-and-channels {
    background: var(--secondary-color-two);
    .row {
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      .columns {
        flex: 1;
        box-sizing: border-box;
        background: var(--secondary-color-three);
        box-shadow: var(--primary-shadow);
        border-radius: 24px;
        padding: 40px;
        margin: 10px;
        height: 400px;
        h3 {
          @include h5();
        }
        p {
          @include p2($fw: 400);
          b {
            @include p2($fw: bold);
          }
          span {
            @include p3($fw: 300);
          }
        }
        h6 {
          @include p2($fw: medium);
        }
        ul {
          li {
            @include p2($fw: 400);
          }
        }
      }
    }
  }
  .cox-faq {
    background: var(--secondary-color-three);
    .accordion {
      .item {
        background: var(--secondary-color-two);
      }
    }
  }
  .divider {
    @include flex($jc: space-between);
    background: var(--primary-color);
    padding: 30px 5%;
    h4 {
      flex: 1;
      @include h4;
      color: var(--secondary-color-two);
    }
    p {
      flex: 1;
      text-align: center;
      @include p2;
      color: var(--secondary-color-two);
      span {
        a {
          @include p2($fw: 500);
          text-decoration: underline;
          &:hover {
            color: var(--secondary-color-one);
          }
        }
      }
    }
  }
}
