@import "../../mixins.scss";

.inner-pages-hero-two {
  width: 100%;
  position: relative;
  overflow: hidden;

  .inner-content {
    background-color: #f5f5f5;
    padding: 1.5%;
    border-radius: 10px;

    .inner-two {
      @include flex($jc: space-between);

      .inner-two-one {
        @include flex($jc: space-between, $ai: normal);
        flex-direction: column;
        background-color: #fff;
        width: 50%;
        max-width: 584px;
        height: 240px;
        box-shadow: 0px 2px 4px 0px #00000024;
        border-radius: 24px;
        text-align: center;
        .button-div {
          text-align: right;
          padding: 2% 1.5% 0% 1.5%;
          @include flex($jc: end);
          button {
            margin: 0 20px;
            @include flex;
            box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.14);
            border: none;
            border-radius: 24px;
            padding: 0 10px;
            height: 40px;
            background-color: #005acc;
            transition: var(--basic);
            overflow: hidden;

            p {
              @include btActive;
              color: #fff;
              margin-left: 10px;
              margin-bottom: 0px;
            }

            .icon-box {
              border: none;
              @include flex;
              border-radius: 100%;
              background: #fff;
              padding: 2px;
              transition: var(--basic);

              .check-icon {
                color: var(--primary-color);
                font-size: 20px;
              }
            }
          }
        }
        .xfinitylogo {
          width: 147px;
          height: 43px;
          margin-bottom: 10px;
        }

        p {
          @include p1;
          margin-bottom: 5px;

          span {
            @include p1;
            color: #0070ff;
          }
        }

        .star {
          width: 104px;
          height: 16px;
        }

        .inner-content {
          background-color: #0070ff;
          text-align: left;
          border-radius: 0px 0px 24px 24px;
          p {
            color: #fff;
            width: 100%;
            max-width: 600px;
          }
        }
      }

      .inner-two-two {
        width: 50%;
        height: 240px;
        max-width: 584px;
      }
    }
    .inner-four-columns {
      @include flex($jc: space-between);
      flex-wrap: nowrap;
      margin: 20px 0px;
      .column-one {
        @include flex($jc: space-between);
        background-color: #fff;
        border-radius: 16px;
        padding: 16px 24px;
        // width: 25%;
        flex: auto;
        margin: 10px;
        .text {
          p {
            @include p3;
            margin-bottom: 10px;
          }
          h6 {
            @include h6;
            text-transform: capitalize;
          }
        }
        .icon {
          img.cross {
            width: 40px;
          }
        }
      }
    }
    p.last-para {
      @include p3($fw: 400);
      text-align: center;
      margin: 20px 0px 0px 0px;
      span {
        @include p3($fw: 400);
        text-decoration: underline;
        color: #0070ff;
      }
    }
  }
}
