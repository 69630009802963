@import "../../../mixins.scss";

.xfinity-review {
  position: relative;
  .inner-hero {
    width: 100%;
    background: url(../../../assets/images/hero-bg.svg) center,
      linear-gradient(
        to right,
        var(--primary-color),
        var(--secondary-color-one)
      );
    background-size: cover;

    .head {
      margin-top: 20px;
      .sect-heading,
      .sect-desc {
        color: #fff;
      }
    }
  }

  .is-xfinity-good {
    background: var(--secondary-color-three);
    padding: 80px 5%;

    .custom-table {
      box-shadow: var(--secndary-shadow);
      table {
        thead {
          tr {
            th {
              text-align: center;
            }
          }
        }
        tbody {
          background: var(--secondary-color-three);
          tr {
            td {
              text-align: center;
            }
          }
        }
      }
    }
  }

  .important-links {
    .all-links {
      @include flex;
      flex-wrap: wrap;
      margin-top: 20px;
      a {
        padding: 12px;
        margin: 12px;
        @include p2($fw: 400);
        text-decoration: underline;
        color: var(--primary-color);
        border: none;
        border-radius: 24px;
        background: #e6f1ff;
        transition: var(--basic);
        text-align: center;
        &:hover {
          color: var(--secondary-color-two);
          background: var(--primary-color);
        }
      }
    }
  }
  .xfinity-region {
    background: var(--secondary-color-three);
    .custom-table {
      box-shadow: var(--secndary-shadow);
      table {
        thead {
          tr {
            th {
              text-align: left;
            }
          }
        }
        tbody {
          background: #fff;
          tr {
            td {
              text-align: left;
            }
            td:nth-of-type(1) {
              text-align: center;
            }
          }
        }
      }
    }
  }
  .xfinity-internet {
    h4.before-heading {
      margin: 30px 0px 10px 0px;
    }
    p.after-heading {
      @include p2($fw: 300);
      span {
        @include p2($fw: 300);
        color: #0070ff;
        &:hover {
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
  }
  .divider {
    @include flex($jc: space-between);
    background: var(--primary-color);
    padding: 30px 5%;
    h4 {
      flex: 1;
      @include h4;
      color: var(--secondary-color-two);
    }
    p {
      flex: 1;
      text-align: center;
      @include p2;
      color: var(--secondary-color-two);
      span {
        a {
          @include p2($fw: 500);
          text-decoration: underline;
          &:hover {
            color: var(--secondary-color-one);
          }
        }
      }
    }
  }
  .xfinity-tv {
    background-color: var(--secondary-color-three);
    .west-region {
      background-color: #fff;
      .package-table table tbody {
        background-color: var(--secondary-color-three);
      }
      h4.before-heading {
        margin: 30px 0px 10px 0px;
      }
      p.after-heading {
        @include p2($fw: 300);
        span {
          @include p2($fw: 300);
          color: #0070ff;
          &:hover {
            text-decoration: underline;
            cursor: pointer;
          }
        }
      }
      .accordion .item {
        background-color: var(--secondary-color-three);
      }
    }
  }
  .divider-two {
    background: var(--primary-color);
    padding: 30px 5%;
    h4 {
      flex: 1;
      text-align: center;
      @include h4;
      color: var(--secondary-color-two);
      margin-bottom: 15px;
    }
    p {
      flex: 1;
      text-align: center;
      @include p2;
      color: var(--secondary-color-two);
      span {
        a {
          @include p2($fw: 500);
          text-decoration: underline;
          &:hover {
            color: var(--secondary-color-one);
          }
        }
      }
    }
  }
  .bundle-packages {
    h4.before-heading {
      margin: 30px 0px 10px 0px;
    }
    p.after-heading {
      @include p2($fw: 300);
      span {
        @include p2($fw: 300);
        color: #0070ff;
        &:hover {
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
  }
  .final-take {
    .head {
      p {
        span {
          @include p2($fw: 300);
          color: #0070ff;
          &:hover {
            text-decoration: underline;
            cursor: pointer;
          }
        }
      }
    }
    .all-links {
      @include flex;
      flex-wrap: wrap;
      margin-top: 20px;
      a {
        padding: 12px;
        margin: 12px;
        @include p2($fw: 400);
        text-decoration: underline;
        color: var(--primary-color);
        border: none;
        border-radius: 24px;
        background: #e6f1ff;
        transition: var(--basic);
        text-align: center;
        &:hover {
          color: var(--secondary-color-two);
          background: var(--primary-color);
        }
      }
    }
  }
  .xfinity-review-faq {
    background: var(--secondary-color-three);
    .faq-wrapper {
      margin-top: 20px;
      .accordion {
        .item {
          background: var(--secondary-color-two);
        }
      }
    }
  }
  .review-endnote{
    padding: 80px 5%;
    background: var(--secondary-color-two);  
    .endnote-container{
    background: var(--secondary-color-three);  
        ul{
            margin-top: 15px;
            margin-bottom: 15px;
            li{
                span{
                    a{
                        @include p2($fw: 400);
                        color: #0070ff;
                        &:hover{
                            text-decoration: underline;
                        }  
                    }
                }
            }
        }
    }
  }
}
