@import "../../../mixins.scss";

.cox-deals {
  position: relative;
  .best-tv-service-offers {
    background: var(--secondary-color-three);
    p {
      @include p3($fw: 400);
      text-align: center;
    }
  }
  .important-links {
    .all-links {
      @include flex;
      flex-wrap: wrap;
      margin-top: 20px;
      a {
        padding: 12px;
        margin: 12px;
        @include p2($fw: 400);
        text-decoration: underline;
        color: var(--primary-color);
        border: none;
        border-radius: 24px;
        background: #e6f1ff;
        transition: var(--basic);
        text-align: center;
        &:hover {
          color: var(--secondary-color-two);
          background: var(--primary-color);
        }
      }
    }
  }
  .cox-deals-promotions {
    background-color: var(--secondary-color-three);
    table {
      tbody {
        background-color: var(--secondary-color-two);
      }
    }
    p.below-table {
      @include p3();
      text-align: center;
      font-style: italic;
    }
    .channels {
      background-color: var(--secondary-color-two);
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.14);
      img.mark {
        width: 44px;
        margin: 0px 0px 25px;
      }
      .right-img {
        text-align: right;
        img.maxlogo {
          width: 87.68px;
          height: 24px;
          margin: 0px 0px 25px;
        }
      }

      a {
        @include p2($fw: 400);
        color: #0070ff;
        &:hover {
          text-decoration: underline;
          cursor: pointer;
        }
      }
      ul {
        li {
          @include p3();
          font-weight: 400;
          span {
            a {
              @include p2($fw: 300);
              color: #0070ff;
              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
      }
      hr.hr {
        border-color: #0070ff;
        margin: 15px 0px;
      }
      .btn {
        overflow: hidden;
        padding: 22px 0 22px 0;
        @include p2($fw: 400);
        color: var(--secondary-color-one);
        text-transform: capitalize;

        &:nth-of-type(1) {
          color: #a5a5a5;
          text-align: right;
        }
        a {
          background: var(--primary-color);
          padding: 11px 24px;
          border: none;
          border-radius: 12px 0 12px 0;
          color: var(--secondary-color-two);
          @include btActive;
          box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.14);
          transition: var(--basic);
          &:hover {
            border-radius: 0 12px 0 12px;
            text-decoration: underline;
          }
        }
      }
    }
  }
  .divider {
    @include flex($jc: space-between);
    background: var(--primary-color);
    padding: 30px 5%;
    h4 {
      flex: 1;
      @include h4;
      color: var(--secondary-color-two);
    }
    p {
      flex: 1;
      text-align: center;
      @include p2;
      color: var(--secondary-color-two);
      span {
        a {
          @include p2($fw: 500);
          text-decoration: underline;
          &:hover {
            color: var(--secondary-color-one);
          }
        }
      }
    }
  }
  .cox-faq {
    .accordion {
      .item {
        background: var(--secondary-color-three);
      }
    }
  }
}
