@import "../../../mixins.scss";

.xfinity-voice {
  position: relative;
  .inner-hero {
    width: 100%;
    background: url(../../../assets/images/hero-bg.svg) center,
      linear-gradient(
        to right,
        var(--primary-color),
        var(--secondary-color-one)
      );
    background-size: cover;

    .head {
      margin-top: 20px;
      .sect-heading,
      .sect-desc {
        color: #fff;
      }
    }
  }
  .is-xfinity-good {
    background: var(--secondary-color-three);
    padding: 80px 5%;

    .custom-table {
      box-shadow: var(--secndary-shadow);
      table {
        thead {
          tr {
            th {
              text-align: center;
              .icon {
                font-size: 20px;
              }
            }
          }
        }
        tbody {
          background: var(--secondary-color-three);
          tr {
            td:nth-of-type(1) {
              color: #000;
            }
            td {
              text-align: center;
            }
          }
        }
      }
    }
  }
  .important-links {
    .all-links {
      @include flex;
      flex-wrap: wrap;
      margin-top: 20px;
      a {
        padding: 12px;
        margin: 12px;
        @include p2($fw: 400);
        text-decoration: underline;
        color: var(--primary-color);
        border: none;
        border-radius: 24px;
        background: #e6f1ff;
        transition: var(--basic);
        text-align: center;
        &:hover {
          color: var(--secondary-color-two);
          background: var(--primary-color);
        }
      }
    }
  }
  .xfinity-voicedetails {
    background-color: var(--secondary-color-three);
    h4.before-heading {
      margin: 30px 0px 10px 0px;
    }
    p.after-heading {
      @include p2($fw: 300);
      span {
        @include p2($fw: 300);
        color: #0070ff;
        &:hover {
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
    .channels {
      background-color: var(--secondary-color-two);
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.14);
      .after-heading {
        margin-top: 10px;
        a {
          @include p2($fw: 300);
          color: #0070ff;
          &:hover {
            text-decoration: underline;
            cursor: pointer;
          }
        }
      }
      table{
        tbody{
            background-color: var(--secondary-color-three);
        }
      }
      .btn {
        overflow: hidden;
        padding: 22px 0 22px 0;
        @include p2($fw: 400);
        color: var(--secondary-color-one);
        text-transform: capitalize;

        &:nth-of-type(1) {
          color: #a5a5a5;
          text-align: right;
        }
        a {
          background: var(--primary-color);
          padding: 11px 24px;
          border: none;
          border-radius: 12px 0 12px 0;
          color: var(--secondary-color-two);
          @include btActive;
          box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.14);
          transition: var(--basic);
          &:hover {
            border-radius: 0 12px 0 12px;
            text-decoration: underline;
          }
        }
      }
      .channel-list {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        list-style: disc;
        padding: 20px;
        li {
          @include p2($fw: 300);
        }
      }
      .left {
        text-align: left;
      }
    }
  }
  .voice-endnote{
    padding: 80px 5%;
    background: var(--secondary-color-two);  
    .endnote-container{
    background: var(--secondary-color-three);  
        ul{
            margin-top: 15px;
            margin-bottom: 15px;
            li{
                span{
                    a{
                        @include p2($fw: 400);
                        color: #0070ff;
                        &:hover{
                            text-decoration: underline;
                        }  
                    }
                }
            }
        }
    }
  }
  .divider {
    @include flex($jc: space-between);
    background: var(--primary-color);
    padding: 30px 5%;
    h4 {
      flex: 1;
      @include h4;
      color: var(--secondary-color-two);
    }
    p {
      flex: 1;
      text-align: center;
      @include p2;
      color: var(--secondary-color-two);
      span {
        a {
          @include p2($fw: 500);
          text-decoration: underline;
          &:hover {
            color: var(--secondary-color-one);
          }
        }
      }
    }
  }
  .xfinity-voice-faq {
    background: var(--secondary-color-three);
    .faq-wrapper {
      margin-top: 20px;
      .accordion {
        .item {
          background: var(--secondary-color-two);
        }
      }
    }
  }
}
