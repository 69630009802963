@import "../../../mixins.scss";

.what-to-watch {
  .what-to-watch-hero {
    width: 100%;
    height: 329px;
    background: url(../../../assets/images/hero-bg.svg) center,
      linear-gradient(
        to right,
        var(--primary-color),
        var(--secondary-color-one)
      );
    padding: 80px 5% 0 5%;
    text-align: center;
    h1 {
      @include h2;
      color: var(--secondary-color-two);
      text-transform: capitalize;
    }
    & > p {
      @include p1;
      color: var(--secondary-color-two);
      text-transform: capitalize;
      line-height: 26px;
      margin: 15px auto;
    }
  }

  .best-internet-tv-offer {
    background-color: var(--secondary-color-three);
    padding: 80px 5% 20px 5%;
    .cards {
      margin-top: -200px;
    }
  }
  
  .endnote-start {
    background-color: var(--secondary-color-three);
    .endnote-container {
      img.coveragemap {
        width: 100%;
        margin-top: 15px;
      }
    }
  }

  .whats-premiering {
    background: var(--secondary-color-three);
  }

  .watch-on-netflix {
    .watch-card {
      background: var(--secondary-color-three);
    }
  }

  .watch-on-max {
    background: var(--secondary-color-three);
  }

  .watch-on-peacock {
    .watch-card {
      background: var(--secondary-color-three);
    }
  }

  .watch-on-prime-video {
    background: var(--secondary-color-three);
  }

  .related-articles {
    .all-links {
      @include flex;
      flex-wrap: wrap;
      margin-top: 20px;
      a {
        padding: 12px;
        margin: 12px;
        @include p2($fw: 400);
        text-decoration: underline;
        color: var(--primary-color);
        border: none;
        border-radius: 24px;
        background: #e6f1ff;
        transition: var(--basic);
        text-align: center;
        &:hover {
          color: var(--secondary-color-two);
          background: var(--primary-color);
        }
      }
    }
  }

  .w2w-wildcards {
    background: var(--secondary-color-three);
  }

  .what-to-watch-cards {
    margin-top: 30px;
    display: block;

    & > a {
      background: var(--primary-color);
      color: var(--secondary-color-two);
      border-radius: 12px 0 12px 0;
      border: none;
      padding: 14px 20px;
      text-transform: uppercase;
      @include btActive;
      transition: var(--basic);
      position: relative;
      @include flex;
      text-align: center;
      &:hover {
        background: var(--hover-color);
        text-decoration: underline;
      }
    }
  }
}
