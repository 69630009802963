@import "../../mixins.scss";

.providers-section {
  //   height: 890px;
  .providers-section-main {
    .tabs {
      @include flex;
      margin: 20px auto;
      button {
        margin: 0 20px;
        @include flex;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.14);
        border: none;
        border-radius: 24px;
        padding: 0 10px;
        height: 40px;
        background-color: #f5f5f5;
        transition: var(--basic);
        overflow: hidden;
        p {
          @include btActive;
          color: #7d7d7d;
          margin-left: 10px;
        }
        .icon-box {
          width: 24px;
          height: 24px;
          max-width: 0px;
          max-height: 0px;
          visibility: hidden;
          opacity: 0;
          border: none;
          @include flex;
          border-radius: 100%;
          background: var(--secondary-color-two);
          padding: 2px;
          transition: var(--basic);
          .check-icon {
            color: var(--primary-color);
            font-size: 20px;
          }
        }
      }

      .active-btn {
        background-color: var(--primary-color);
        p {
          color: var(--secondary-color-two);
        }
        .icon-box {
          opacity: 1;
          @include flex;
          max-width: 24px;
          max-height: 24px;
          visibility: visible;
        }
      }
    }

    .cards {
      @include flex();
      flex-wrap: wrap;
      opacity: 0;
      transition: var(--basic);
      overflow: hidden;
    }

    .animate {
      opacity: 1 !important;
    }
  }
}
