@import "../../../mixins.scss";

.spectrum-channel-lineups {
  position: relative;
  .inner-hero {
    width: 100%;
    background: url(../../../assets/images/hero-bg.svg) center,
      linear-gradient(
        to right,
        var(--primary-color),
        var(--secondary-color-one)
      );
    background-size: cover;

    .head {
      margin-top: 20px;
      .sect-heading,
      .sect-desc {
        color: #fff;
      }
    }
  }
  .is-spectrum-good {
    background: var(--secondary-color-three);
    padding: 80px 5%;
  }
  .important-links {
    .all-links {
      @include flex;
      flex-wrap: wrap;
      margin-top: 20px;
      a {
        padding: 12px;
        margin: 12px;
        @include p2($fw: 400);
        text-decoration: underline;
        color: var(--primary-color);
        border: none;
        border-radius: 24px;
        background: #e6f1ff;
        transition: var(--basic);
        text-align: center;
        &:hover {
          color: var(--secondary-color-two);
          background: var(--primary-color);
        }
      }
    }
  }
  .spectrum-tv-channels-packages {
    background-color: var(--secondary-color-three);
    h4.before-heading {
      margin: 30px 0px 10px 0px;
    }
    p.after-heading {
      @include p2($fw: 300);
      span {
        @include p2($fw: 300);
        color: #0070ff;
        &:hover {
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
    .channels {
      background-color: var(--secondary-color-two);
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.14);
      .after-heading {
        margin-top: 10px;
        a {
          @include p2($fw: 300);
          color: #0070ff;
          &:hover {
            text-decoration: underline;
            cursor: pointer;
          }
        }
      }
      p {
        span {
          a {
            @include p2($fw: 300);
            color: var(--primary-color);
            &:hover {
              border-radius: 0 12px 0 12px;
              text-decoration: underline;
            }
          }
        }
      }
      ul {
        li {
          @include p2($fw: 300);
          span {
          a{
            @include p2($fw: 300);
            color: #0070ff;
          }
          }
        }
      }
      .below-table {
        text-align: center;
      }
      table {
        tbody {
          background-color: var(--secondary-color-three);
        }
      }
      .btn {
        overflow: hidden;
        padding: 22px 0 22px 0;
        @include p2($fw: 400);
        color: var(--secondary-color-one);
        text-transform: capitalize;

        &:nth-of-type(1) {
          color: #a5a5a5;
          text-align: right;
        }
        a {
          background: var(--primary-color);
          padding: 11px 24px;
          border: none;
          border-radius: 12px 0 12px 0;
          color: var(--secondary-color-two);
          @include btActive;
          box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.14);
          transition: var(--basic);
          &:hover {
            border-radius: 0 12px 0 12px;
            text-decoration: underline;
          }
        }
      }
      .channel-list {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        list-style: none;
        padding: 20px 0px 20px 0px;
        margin: 0px;
        li {
          @include p2($fw: 300);
        }
      }
      .left {
        text-align: left;
      }
    }
    .limited-time-offer {
      padding: 1% 0%;
      background: var(--secondary-color-two);
      .endnote-container {
        background-color: var(--secondary-color-three);
        ul {
          list-style: none;
          margin: 0;
          padding: 0;
        }
      }
    }
    .review-endnote {
      margin-top: 15px;
      .endnote-container {
        background-color: var(--secondary-color-three);
      }
    }
  }
  .bundles-and-pricing{
    background: var(--secondary-color-two);
    .below-table {
        text-align: center;
        @include p2($fw:300);
        font-style: italic;
        margin-top: 15px;
      }
  }
  .spectrum-review-faq{
    background: var(--secondary-color-three);
  }
  .spectrum-review-endnote {
    padding: 80px 5%;
    background: var(--secondary-color-two);
    .endnote-container {
      background: var(--secondary-color-three);
      .bold {
        @include p2($fw: 600);
      }
      .below-table {
        @include p2($fw: 300);
        margin-top: 10px;
        font-style: italic;
      }
      ul {
        margin-top: 15px;
        margin-bottom: 15px;
        list-style: none;
        margin-left: 0px;
        margin-right: 0px;
        li {
          span {
            a {
              @include p2($fw: 400);
              color: #0070ff;
              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
      }
      .endnote-review {
        @include p1($fw: 300);
      }
    }
  }
}
