@import "../../../mixins.scss";

.xfinity-bundles {
  position: relative;

  .best-xfinity-bundles-offers {
    background: var(--secondary-color-three);
  }

  .xfinity-bundles {
    .watch-card {
      align-items: center;
      .watch-card-content {
        .title {
          @include h2;
          span {
            @include h2;
            color: #0070ff;
          }
        }
        ul {
          list-style: disc;
          padding: 0px 0px 0px 20px;
          li {
            @include p1($fw: 300);
          }
        }
      }
    }
  }
  .bundle-packages {
    background-color: var(--secondary-color-three);
    .west-region {
      background-color: #fff;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.14);
      .package-table table tbody {
        background-color: var(--secondary-color-three);
      }
      h4.before-heading {
        margin: 30px 0px 10px 0px;
      }
      p.after-heading {
        @include p2($fw: 300);
        span {
          @include p2($fw: 300);
          color: #0070ff;
          &:hover {
            text-decoration: underline;
            cursor: pointer;
          }
        }
      }
      .accordion .item {
        background-color: var(--secondary-color-three);
      }
    }
  }
  .divider-two {
    background: var(--primary-color);
    padding: 30px 5%;
    h4 {
      flex: 1;
      text-align: center;
      @include h4;
      color: var(--secondary-color-two);
      margin-bottom: 15px;
    }
    p {
      flex: 1;
      text-align: center;
      @include p2;
      color: var(--secondary-color-two);
      span {
        a {
          @include p2($fw: 500);
          text-decoration: underline;
          &:hover {
            color: var(--secondary-color-one);
          }
        }
      }
    }
  }
  .xfinity-services {
    background-color: var(--secondary-color-two);
    .west-region {
      background-color: #fff;
      .package-table table tbody {
        background-color: var(--secondary-color-three);
      }
      h4.before-heading {
        margin: 30px 0px 10px 0px;
      }
      p.after-heading {
        @include p2($fw: 300);
        span {
          @include p2($fw: 300);
          color: #0070ff;
          &:hover {
            text-decoration: underline;
            cursor: pointer;
          }
        }
      }
      .accordion .item {
        background-color: var(--secondary-color-three);
      }
    }
  }
  .xfinity-bundles-faq {
    background: var(--secondary-color-three);
    .faq-wrapper {
      margin-top: 20px;
      .accordion {
        .item {
          background: var(--secondary-color-two);
        }
      }
    }
  }
}
