@import "../../mixins.scss";

.package-card {
  min-width: 292px;
  max-width: 292px;
  min-height: 285px;
  background-color: var(--secondary-color-two);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.14);
  border-radius: 24px;
  @include flex($jc: flex-start, $ai: flex-start);
  flex-direction: column;
  position: relative;
  padding: 16px 22px;
  margin: 10px 15px 10px 0;
  &:last-child {
    margin: 10px 0;
  }

  .logo {
    align-self: flex-end;
    width: 113px;
    max-width: 113px;
    height: 24px;
    max-height: 24px;
    height: auto;
    @include flex;
    img{
      width: 113px;
      height: 24px;
    }
  }

  .tag {
    border: 1px solid var(--primary-color);
    background-color: #e6f1ff;
    border-radius: 24px;
    padding: 10px;
    p {
      font-family: "Lato", sans-serif;
      font-size: 1.4rem;
      font-weight: 600;
      color: var(--primary-color);
    }
  }

  div {
    .package-name {
      @include h5;
      color: var(--secondary-color-one);
      margin: 10px auto;
    }

    .package-points {
      list-style-type: circle;
      margin-left: 18px;
      li {
        @include p2($fw: 300);
        color: var(--secondary-color-one);
        margin: 5px auto
      }
    }
  }

  a {
    @include flex();
    text-transform: capitalize;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 150px;
    height: 48px;
    background-color: var(--primary-color);
    border: none;
    border-radius: 24px 0 24px 0;
    cursor: pointer;
    transition: var(--basic);
    overflow: hidden;
    p {
      @include btActive;
      color: var(--secondary-color-two);
      transition: var(--basic);
    }
    .arrow-icon-box {
      @include flex;
      transition: var(--basic);
      background-color: var(--secondary-color-two);
      border-radius: 100%;
      border: none;
      padding: 5px;
      margin-left: 70px;
      margin-right: -100px;
      .upright-arrow-icon {
        color: var(--primary-color);
        font-size: 20px;
      }
    }

    &:hover {
      .arrow-icon-box {
        margin-right: 0;
        margin-left: 12px;
      }
    }
  }
}
