@import "../../../mixins.scss";

.xfinity-customer-service {
  position: relative;
  .xfinity-customer-hero {
    position: relative;
    width: 100%;
    height: 450px;
    background: url(../../../assets/images/hero-bg.svg) center,
      linear-gradient(
        to right,
        var(--primary-color),
        var(--secondary-color-one)
      );
    padding: 80px 5% 0 5%;
    text-align: center;
    h1 {
      @include h2;
      color: var(--secondary-color-two);
      text-transform: capitalize;
      margin-top: 20px;
    }
    & > p {
      @include p1;
      color: var(--secondary-color-two);
      text-transform: capitalize;
      line-height: 26px;
      margin: 15px auto;
    }
    .container-img {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      max-width: 1280px;
      margin: auto;
    }
    .column-img {
      flex: 1;
      margin: 10px;
      box-sizing: border-box;
    }
  }
  .best-internet-tv-offer {
    background-color: var(--secondary-color-three);
    padding: 80px 5% 20px 5%;
    .cards {
      margin-top: -200px;
    }
  }
  .endnote-start {
    background-color: var(--secondary-color-three);
  }
  .important-links {
    .all-links {
      @include flex;
      flex-wrap: wrap;
      margin-top: 20px;
      a {
        padding: 12px;
        margin: 12px;
        @include p2($fw: 400);
        text-decoration: underline;
        color: var(--primary-color);
        border: none;
        border-radius: 24px;
        background: #e6f1ff;
        transition: var(--basic);
        text-align: center;
        &:hover {
          color: var(--secondary-color-two);
          background: var(--primary-color);
        }
      }
    }
    .endnote-start {
      margin-top: 15px;
      background-color: var(--secondary-color-two);
      .endnote-container {
        background-color: var(--secondary-color-three);
        ul {
          list-style: decimal;
        }
        p {
          i {
            @include p2($fw: 400);
          }
        }
      }
    }
  }
  .customer-phone-number {
    background-color: var(--secondary-color-three);
  }
  .call-back {
    background-color: var(--secondary-color-two);
    box-shadow: 0px 2px 4px 0px #00000024;
    border-radius: 24px;
    width: 100%;
    margin: auto;
    max-width: 600px;
    height: 165px;
    position: relative;
    .call-back-container {
      display: flex;
      flex-direction: column;
      padding: 35px 30px;
      .icon-box {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 20px;
        img {
          width: 40px;
        }
        .icon-box-content {
          h4 {
            @include p1($fw: 600);
            color: var(--primary-color);
            text-transform: capitalize;
          }
          p {
            @include p2($fw: 300);
          }
        }
      }
      hr {
        margin: 15px 0px;
        border-color: var(--primary-color);
      }
    }
    a {
      @include flex();
      text-transform: capitalize;
      position: absolute;
      bottom: 0;
      right: 0;
      width: 220px;
      height: 48px;
      background-color: var(--primary-color);
      border: none;
      border-radius: 24px 0 24px 0;
      cursor: pointer;
      transition: var(--basic);
      overflow: hidden;
      p {
        @include btActive;
        color: var(--secondary-color-two);
        transition: var(--basic);
        margin: 0;
      }
      .arrow-icon-box {
        @include flex;
        transition: var(--basic);
        background-color: var(--secondary-color-two);
        border-radius: 100%;
        border: none;
        padding: 5px;
        margin-left: 70px;
        margin-right: -100px;
        .upright-arrow-icon {
          color: var(--primary-color);
          font-size: 20px;
        }
      }

      &:hover {
        .arrow-icon-box {
          margin-right: 0;
          margin-left: 12px;
        }
      }
    }
  }
  .service-hours {
    background-color: var(--secondary-color-two);
    .call-back {
      background-color: var(--secondary-color-three);
    }
  }
  .customer-outages {
    background-color: var(--secondary-color-three);
  }
  .service-online {
    background-color: var(--secondary-color-two);
    .call-back {
      background-color: var(--secondary-color-three);
    }
  }
  .online-support {
    background-color: var(--secondary-color-three);
  }
  .tech-support {
    background-color: var(--secondary-color-two);
    .endnote-start {
      background-color: var(--secondary-color-two);
      margin: 15px 0px 0px 0px;
      .endnote-container {
        background-color: var(--secondary-color-three);
        ul {
          list-style: none;
          margin: 0px;
          li {
            a {
              @include p2;
              color: var(--primary-color);
            }
          }
        }
      }
    }
  }
  .support-business {
    background-color: var(--secondary-color-three);
  }
  .tech-support {
    background-color: var(--secondary-color-two);
    .two-callbacks {
      display: flex;
      flex-wrap: wrap;
      .call-back {
        flex: 1;
        margin: 10px;
        height: auto;
      }
    }
  }
  .suspend-xfinity-service {
    background-color: var(--secondary-color-three);
    .endnote-start {
      margin-top: 20px;
      .endnote-container {
        .call-back {
          background-color: var(--secondary-color-three);
          margin-top: 15px;
        }
        ul {
          list-style: none;
          margin: 0;
        }
      }
    }
  }
  .divider {
    @include flex($jc: space-between);
    background: var(--primary-color);
    padding: 30px 5%;
    h4 {
      flex: 1;
      @include h4;
      color: var(--secondary-color-two);
    }
    p {
      flex: 1;
      text-align: center;
      @include p2;
      color: var(--secondary-color-two);
      span {
        a {
          @include p2($fw: 500);
          text-decoration: underline;
          &:hover {
            color: var(--secondary-color-one);
          }
        }
      }
    }
  }
  .intertnet-review-endnote {
    padding: 80px 5%;
    background: var(--secondary-color-two);
    .endnote-container {
      background: var(--secondary-color-three);
      ul {
        margin-top: 15px;
        margin-bottom: 15px;
        list-style: none;
        margin-left: 0;
        margin-right: 0;
        li {
          span {
            a {
              @include p2($fw: 400);
              color: #0070ff;
              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
      }
      .endnote-review {
        @include p1($fw: 300);
      }
    }
  }
}
