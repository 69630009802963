@import "../../mixins.scss";

.mobile-nav {
  @include flex($jc: flex-start);
  background: var(--secondary-color-two);
  position: absolute;
  left: 0;
  width: 280px;
  border: none;
  border-radius: 0 0 30px 0;
  padding: 20px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.14);

  .icon-box {
    @include flex;
    background: var(--primary-color);
    filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.14));
    height: 30px;
    width: 30px;
    margin-right: 10px;
    border-radius: 100%;
    border: none;
    .icon{
        color: var(--secondary-color-two);
        font-size: 16px;
    }
  }

  .logo{
    img{
        width: 202px;
        height: auto;
    }
  }

  @media(min-width: 910px){
    display: none;
  }
}
